import React from 'react';
import { Ranker } from '../components/elements/ranker';
import { SplitLayout } from '../components/views/split';
import styles from './styles/dashboard.module.scss';

export function RankingPage() {
	return (
		<SplitLayout>
			<div className={styles.main}>
				<Ranker />
			</div>
		</SplitLayout>
	);
};