import { useState } from "react";
import styles from './styles/searchbar.module.scss';

type SearchProps = {//bad style - type should not be any!
	handleKeyUp: any;
	resetTable: any;
}

export const SearchBar:React.FunctionComponent<SearchProps> = (SearchProps) => {
	const { handleKeyUp, resetTable } = SearchProps;
	const [search, setSearch] = useState('');

	const handleChange = (e: any) => {
		if (e.target.value === "") {
			handleReset(true);
		}
		setSearch(e.target.value);
	};

	const handleReset = (del:Boolean) => {
		if (search !== "" || del === true) {
			resetTable();  
			setSearch("");
		}
	};

	return (
		<div className={styles.inputWithButton}>
			<input type="text" className={styles.searchBar} onChange={handleChange} onKeyUp={handleKeyUp} value={search}/>
			<button id={styles.clear} onClick={() => handleReset(false)}>&#10539;</button>
		</div>
	);
};