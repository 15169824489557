import { useEffect, useState } from 'react';
import { getRankables, rankPerson } from '../../api/ranker';
import styles from './styles/ranker.module.scss';
import linkedInLogo from '../../assets/images/linkedin.svg';

export const Ranker = () => {
	const [initialized, setInitialized] = useState<boolean>(false);
	const [rankables, setRankables] = useState();
	const [current, setCurrent] = useState(0);
	const [responding, setResponding] = useState(false);
	useEffect(() => {
		window.addEventListener('keypress', handleKeypress);
		async function loadData() {
			let data = await getRankables();
			if (data) {
				setRankables(data);
			}
			setInitialized(true);
		}

		if (!initialized) {
			loadData();
		}

		return () => {
			window.removeEventListener('keypress', handleKeypress);
		}
	});

	async function handleKeypress(e: any) {
		console.log(e.key);
		if (responding) return;
		setResponding(true);
		if (e.key === "j" || e.key === "J") rank(0);
		if (e.key === "k" || e.key === "K") rank(1);
		if (e.key === "a" || e.key === "A") rank(-1);
		setResponding(false);
	}
	
	function rank(value:number) {
		if (!rankables) return;
		if (value === -1) {
			if (current > 0) setCurrent(current - 1);
			return;
		}
		rankPerson(rankables[current]["id"], value);
		setCurrent(current + 1);
	}

	return (
		<div className={styles.main}>
			{ rankables && <Rankable data={rankables[current]} rank={rank} /> }
			{ !initialized && <h1 className={styles.loading}>Firing up the engines...</h1>}
		</div>
	);
}

export const Rankable = ({...props}: any) => {
	let { first_name, last_name, headline, linkedin, photo } = props.data;
	// primary_email, 
	console.log(photo);

	function good() {
		props.rank(1);
	}

	function pass() {
		props.rank(0);
	}

	function back() {
		props.rank(-1);
	}

	return (
		<div className={styles.rankable}>
			<div className={styles.person}>
				{photo && <img className={styles.photo} src={"https://pearos-people-photos.s3-us-west-2.amazonaws.com/" + photo} alt="" />}
				<div className={styles.info}>
					<span className={styles.header}>
						<h1>{first_name + " " + last_name}</h1>
						<a href={"https://www.linkedin.com/in/" + linkedin} target="_blank" rel="noreferrer">
							<img src={linkedInLogo} className={styles.linkedIn} alt={linkedin} />
						</a>
					</span>
					{/* <a className={styles.linkedIn} href={"https://www.linkedin.com/in/" + linkedin} target="_blank" rel="noreferrer">linkedin.com/in/{linkedin}</a> */}
					{/* <h3>{primary_email}</h3> */}
					<h2>{headline}</h2>
				</div>
			</div>
			<div className={styles.controls}>
				<button className={styles.pass} onClick={good}><div className={styles.key}>j</div>Pass/Average</button>
				<button className={styles.good} onClick={pass}><div className={styles.key}>k</div>Exceptional (Top 1%)</button>
				<button className={styles.back} onClick={back}><div className={styles.key}>a</div>Undo (Back)</button>
			</div>
		</div>
	);
}