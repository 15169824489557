// This is a grid-based CRM base
// Replicate modularly where needed – only one instance per view

import { DataGridPremium, gridFilteredSortedRowIdsSelector, GridSelectionModel, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid-premium';
import styles from './styles/base.module.scss';
import './styles/mui.css';
import { allPeople } from '../../api/people';
import { searchPeople } from '../../api/search';
import { useEffect, useState } from 'react';
import { Connections, Signal } from './indicators';
import linkedIn from '../../assets/images/linkedin.svg';
import profile from '../../assets/images/profile.jpg';
import { SearchBar } from './searchbar';

type Props = {
	filters?: any,
	selected: GridSelectionModel,
	setSelected: Function,
	preSelected?: GridSelectionModel,
}

export const Base = ({ filters, selected, setSelected, preSelected }: Props) => {
	// const { user, logout } = useAuth();
	const apiRef = useGridApiRef();
	const apiRefSearch = useGridApiRef();
	const [data, setData] = useState<any>({
		rows: [],
		columns: []
	});

	const [searchData, setSearchData] = useState<any>({
		rows: [],
		columns: []
	});

	const [initialized, setInitialized] = useState<boolean>(false);
	const [resultType, setResultType] = useState<any>("full");

	const format = (people:any, pearPeople:any) => {
		return {
			rows: people.data.people,
			columns: [
				{
					field: "person",
					headerName: "Person",
					width: 200,
					renderCell: (row: any) => {
						return (
							<div className={styles.columnPerson}>
								{ row.row.photo && row.row.photo !== "/proxycurl/media/imgs/placeholder-profile.png" ?
									<img
										src={"https://pearos-people-photos.s3-us-west-2.amazonaws.com/" + row.row.photo}
										alt=""
										className={styles.columnPersonPhoto}
										onError={({ currentTarget }) => {
											currentTarget.onerror = null;
											// row.row["photo"] = null;
											currentTarget.src= profile;
										}}
									/>
									:
									<div className={styles.columnPersonPhotoPlaceholder}>{`${row.row.first_name[0] || ''}${row.row.last_name[0] || ''}`}</div>
								}
								<div className={styles.columnPersonText}>
									<h1>{`${row.row.first_name || ''} ${row.row.last_name || ''}`}</h1>
									<h2>{row.row.primary_email || ''}</h2>
								</div>
							</div>
						);
					},
					valueGetter: (params: GridValueGetterParams) =>
						`${params.row.first_name || ''} ${params.row.last_name || ''}`
				},
				// { field: "first_name", headerName: "First Name", width: 150 },
				// { field: "last_name", headerName: "Last Name", width: 150 },
				{ field: "headline", headerName: "Current", width: 300 },
				{
					field: "location",
					headerName: "Location",
					width: 150,
					valueGetter: (params: GridValueGetterParams) =>
						`${params.row.metadata ? params.row.metadata["granular_location"] || '' : ''}`
				},
				{
					field: "signal",
					headerName: "Signal",
					width: 120,
					renderCell: (row: any) => {
						return (
							<Signal value={row.row.signal} />
						);
					},
					valueGetter: (params: GridValueGetterParams) => params.row.signal
				},
				{
					field: "programs",
					headerName: "Programs",
					width: 150,
					renderCell: (row: any) => {
						return (
							<div className={styles.columnPrograms}>
								{
									row.row.programs ? row.row.programs.map((x:any, index:number) =>
										<div className={styles.columnProgramsItem} key={ index }>
											{ x["program"] + " " + x["year"] }
										</div>
									) : ""
								}
							</div>
						);
					}
				},
				// {
				// 	field: "most-recent-role",
				// 	headerName: "Most Recent Role",
				// 	width: 150,
				// 	valueGetter: (params: GridValueGetterParams) =>
				// 		`${params.row.metadata ? params.row.metadata["most_recent_role"] || '' : ''}`
				// },
				{
					field: "connections",
					headerName: "Connections",
					width: 150,
					minWidth: 73,
					renderCell: (row: any) => {
						if (row.row.metadata && row.row.metadata["connections"]) {
							// console.log(row.row.metadata)
							return (<Connections connections={row.row.metadata.connections} pear={pearPeople} width={row.colDef.width}/>);
						}
						return (<div />);
					},
					valueGetter: (params: GridValueGetterParams) =>
						params.row.metadata && params.row.metadata["connections"] ? params.row.metadata["connections"] : 0
				},
				{ field: "primary_email", headerName: "Primary Email", width: 150 },
				{
					field: "contacts",
					headerName: "Contacts",
					renderCell: (row: any) => {
						if (row.row.metadata && row.row.linkedin) {
							return (
								<div className={styles.columnContacts}>
									<a href={"https://www.linkedin.com/in/" + row.row.linkedin} target="_blank" rel="noreferrer">
										<img src={linkedIn} className={styles.linkedIn} alt={row.row.linkedin} />
									</a>
								</div>
							);
						}
						return (<div />);
					},
					width: 150
				}
			]
		};
	};

	useEffect(() => {
		async function loadData() {
			let people:any = await allPeople(100000);
			let pearPeople:any = people.data.pear;
			let formatted = format(people, pearPeople);
			setData(formatted);
			if (preSelected?.length !== 0) {
				setSelected(preSelected);
			} else {
				const filteredRows = gridFilteredSortedRowIdsSelector(apiRef);
				setSelected([filteredRows[0]]);
			}
		}
		if (!initialized) {
			setInitialized(true);
			loadData();
		} 
	}, [apiRef, data, initialized, preSelected, selected, setSelected]);

	async function handleKeyUp(e:any) {
		if (e.key === 'Enter' && e.target.value !== "") {
			setResultType("search");
			setSearchData({
				rows: [],
				columns: []
			});
		  	let people:any = await searchPeople(e.target.value, 100000);
			if (people.data.people.length === 0) {
				setResultType(null);
			}
			let pearPeople:any = []; // need to fix?
			let formatted = format(people, pearPeople);
			setSearchData(formatted);
		}
	};

	const resetTable = () => {
		setResultType("full");
		setSearchData({
			rows: [],
			columns: []
		});
	}

	function handleKeyDown(event:any) {
		const filteredRows = resultType === "full" ? gridFilteredSortedRowIdsSelector(apiRef) : gridFilteredSortedRowIdsSelector(apiRefSearch);
		if (selected.length === 1) { // only one row selected if we are to move with arrow keys
			if (event.keyCode === 38) { // up key
				let currentRowLoc = filteredRows.indexOf(selected[0]);
				if (currentRowLoc !== 0) {
					setSelected([filteredRows[currentRowLoc-1]]);
				}
			}
			if (event.keyCode === 40) { // downkey
				let currentRowLoc = filteredRows.indexOf(selected[0]);
				if (currentRowLoc !== filteredRows.length-1) {
					setSelected([filteredRows[currentRowLoc+1]]);
				}
			}
		} 
    }

	return (
		<div className={styles.main} onKeyDown={handleKeyDown}>
			<SearchBar handleKeyUp={handleKeyUp} resetTable={resetTable}/>
				{ resultType === "full" ? 
					<DataGridPremium
						apiRef={apiRef}   
						sx={{
							".MuiDataGrid-virtualScroller": {
							borderTop: "1px solid rgba(224, 224, 224, 1)"
							}
						}}
						{...data}
						rowHeight={75}
						hideFooter
						loading={data.rows.length === 0}
						filterModel={ filters }
						onSelectionModelChange={(newSelectionModel) => {
							if (initialized) {
								setSelected(newSelectionModel);
							}
						}}
						// rowKeyGetter={ (row:any) => row.id }
						selectionModel={selected}
						initialState={{ pinnedColumns: { left: ['person'] } }}
					/> : resultType === "search" ?
							<DataGridPremium
								apiRef={apiRefSearch} 
								sx={{
									".MuiDataGrid-virtualScroller": {
									borderTop: "1px solid rgba(224, 224, 224, 1)"
									}
								}}
								{...searchData}
								rowHeight={75}
								hideFooter
								loading={searchData.rows.length === 0}
								filterModel={ filters }
								onSelectionModelChange={(newSelectionModel) => {
									setSelected(newSelectionModel);
								}}
								// rowKeyGetter={ (row:any) => row.id }
								selectionModel={selected}
								initialState={{ pinnedColumns: { left: ['person'] } }}
							/> :
							<div className={styles.noResults}> No results, please try another search! </div>
				}
		</div>
	);
};
