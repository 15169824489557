import { authRequest, Endpoints, Methods } from "./config";
const pako = require('pako');
// import { promisify } from "util";
// const asyncUnzip = promisify(unzip);

export async function searchPeople(searchQuery: string, max:number=100) {
    let data = await authRequest(Endpoints.CRM_PEOPLE_SEARCH, Methods.GET, {query:searchQuery, max:max});
    if (data.data && data.data.people) {
		data.data["people"] = JSON.parse(pako.inflate(new Uint8Array(atob(data.data.people).split("").map((x) => { return x.charCodeAt(0); })), { to: "string" }));
	}
	return data;
}
