import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './hooks/useAuth';
import { LicenseInfo } from '@mui/x-data-grid-premium';
// import { generateLicense } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey("72c4a62f5296c4e629f5e534ae8e44d4Tz0xMDAwLEU9MTY2OTQ4NTY1MTM4OCxTPXByZW1pdW0sTE09cGVycGV0dWFsLEtWPTI=");
// let license = generateLicense({
// 	orderNumber: "1000",
// 	expiryDate: new Date(),
// 	scope: "premium",
// 	licensingModel: "perpetual"
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
