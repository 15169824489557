import axios from "axios";

export enum Endpoints {
	CRM_PEOPLE = "crm/people",
	CRM_PEOPLE_METADATA = "crm/people/metadata",
	CRM_PERSON = "crm/person",
	CRM_LIST = "crm/lists",
	CRM_PERSON_LIST = "crm/person/lists",
	CRM_DELETE_LIST = "crm/person/lists/delete",
	CRM_PEOPLE_SEARCH = "crm/people/search",
	RANKING = "crm/ranking",
	SELF = "auth/me"
}

export enum Methods {
	GET = "GET",
	POST = "POST",
	PUT = "PUT",
	DELETE = "DELETE"
}

function urlWith(endpoint: Endpoints) {
	return "https://q6kfbwifh3.execute-api.us-west-2.amazonaws.com/v3/" + endpoint;
}

export async function authRequest(url: Endpoints, method: Methods, data?: any) {
	const value = window.localStorage.getItem("user");
	if (value) {
		try {
			let user = JSON.parse(value);
			let options:any = {
				method: method,
				url: urlWith(url),
				headers: {
					Authorization: "Bearer " + user.token
				}
			};
			if (method === Methods.GET && data) options["params"] = data;
			if (method === Methods.POST && data) options["data"] = data;
			let result = await axios(options);
			console.log(result.status);
			if (result.status < 300) {
				return result.data;
			} else {
				return null;
			}
		} catch(e: any) {
			console.log(e);
			if (e.response.status === 401) {
				console.log("Session Invalid");
				window.localStorage.setItem("user", JSON.stringify(null));
				window.location.href = "/";
				return null;
			} 
			return null;
		}
	} else {
		return null;
	}
}