import React from 'react';
import logo from '../../assets/images/pearos.svg';
import styles from './styles/splash.module.scss';

export default function Splash() {
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.welcome}>
					<h1>Welcome to</h1>
					<img alt="PearOS" src={logo} />
				</div>
			</div>
			<div className={styles.screen} />
			<div className={styles.scene}>
				<div className={styles.wrap}>
					<div className={`${styles.wall} ${styles.wallright}`}></div>
					<div className={`${styles.wall} ${styles.wallleft}`}></div>   
					<div className={`${styles.wall} ${styles.walltop}`}></div>
					<div className={`${styles.wall} ${styles.wallbottom}`}></div> 
					<div className={`${styles.wall} ${styles.wallback}`}></div>    
				</div>
				<div className={styles.wrap}>
					<div className={`${styles.wall} ${styles.wallright}`}></div>
					<div className={`${styles.wall} ${styles.wallleft}`}></div>   
					<div className={`${styles.wall} ${styles.walltop}`}></div>
					<div className={`${styles.wall} ${styles.wallbottom}`}></div>   
					<div className={`${styles.wall} ${styles.wallback}`}></div>    
				</div>
			</div>
		</div>
	);
};