import { Link } from "react-router-dom";
import styles from './styles/navbar.module.scss';
import peopleIcon from '../../assets/images/people.svg';
import starIcon from '../../assets/images/star.svg';
import logoutIcon from '../../assets/images/logout.svg';
import { useAuth } from "../../hooks/useAuth";

export const NavBar = () => {
	return (
		<div className={styles.main}>
			<div className={styles.navBar}>
				<NavBarItem icon={peopleIcon} title="People" route="/dashboard" />
				<NavBarItem icon={starIcon} title="Ranking" route="/rank" />
			</div>
			
			<div className={styles.navBar}>
				<LoginSection />
				<LogoutSection />
			</div>
			
		</div>
	);
};

export const LoginSection = () => {
	const user = window.localStorage.getItem("user");
	let photo = null;
	if (user) {
		photo = JSON.parse(user)["picture"];
	}
	
	return (
		<div>
			<img className={styles.loginCircle} src={photo} alt="Profile" referrerPolicy="no-referrer" />
		</div>
	);
}

export const LogoutSection = () => {

	const { logout } = useAuth();
	
	return (
		<div onClick={logout} style={{transform: 'scale(0.5)'}}>
			<NavBarItem  icon={logoutIcon} title="Logout" />
		</div>
	);
}

const NavBarItem = ({...props}: any) => {
	return (
		<Link to={props.route}>
			<div className={styles.navBarItem}>
				<img src={props.icon} alt={props.title} />
			</div>
		</Link>
	);
}