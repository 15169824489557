import { authRequest, Endpoints, Methods } from "./config";
const pako = require('pako');
// import { promisify } from "util";
// const asyncUnzip = promisify(unzip);

export async function allPeople(max=100) {
	// let metadata = await ;
	// let data = await ;
	let result = await Promise.all([
		authRequest(Endpoints.CRM_PEOPLE_METADATA, Methods.GET, {
			max: max
		}),
		authRequest(Endpoints.CRM_PEOPLE, Methods.GET, {
			max: max
		})
	]);
	let metadata = result[0];
	let data = result[1];
	if (data.data && data.data.people) {
		data.data["people"] = JSON.parse(pako.inflate(new Uint8Array(atob(data.data.people).split("").map((x) => { return x.charCodeAt(0); })), { to: "string" }));
		// console.log(data.data["people"]);
	}
	if (metadata.data && metadata.data.people) {
		data.data["pear"] = metadata.data.pear;
		metadata = JSON.parse(pako.inflate(new Uint8Array(atob(metadata.data.people).split("").map((x) => { return x.charCodeAt(0); })), { to: "string" }));
		// console.log(metadata);
	}
	metadata = metadata.reduce((accumulator: any, current: any) => {
		accumulator[current["id"]] = current["metadata"];
		return accumulator;
	}, {});
	data.data["people"] = data.data["people"].map((x:any) => {
		x["metadata"] = metadata[x.id];
		return x;
	});
	return data;
}

export async function personWith(id:number) {
	let data = await authRequest(Endpoints.CRM_PERSON, Methods.GET, {
		id: id
	});
	return data.data;
}