import { authRequest, Endpoints, Methods } from "./config";

export async function getRankables() {
	let data = await authRequest(Endpoints.RANKING, Methods.GET);
	return data.data;
}

export async function rankPerson(id:number, value:number) {
	let success = await authRequest(Endpoints.RANKING, Methods.POST, {
		person: id,
		value: value
	});
	return success.data;
}