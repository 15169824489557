import { GridSelectionModel } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Base } from '../components/elements/base';
import { SideBar } from '../components/elements/sidebar';
import { SplitLayout } from '../components/views/split';
import styles from './styles/dashboard.module.scss';

export function DashboardPage () {
	let { id } = useParams();
	const [selected, setSelected] = useState<GridSelectionModel>(id ? [Number(id)] : []);
	
	return (
		<SplitLayout>
			<div className={styles.main}>
				<Base
					selected={selected}
					setSelected={setSelected}
					preSelected={selected}
				/>
			</div>
			<SideBar
				selected={selected}
				type="person"
			/>
		</SplitLayout>
	);
};