import { ReactNode, useEffect, useState } from "react";
import { personWith } from "../../api/people";
import styles from './styles/sidebar.module.scss';
import { CircularProgress } from "@mui/material";
import linkedInLogo from "../../assets/images/linkedin.svg";
import meetingLogo from "../../assets/images/meeting.svg";
import leftArrow from "../../assets/images/leftarrow.svg";
import rightArrow from "../../assets/images/rightarrow.svg";
import { Circle } from "./indicators";
import profile from '../../assets/images/profile.jpg';
import Autocomplete from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import { authRequest, Endpoints, Methods } from "../../api/config";

/*type TabsProps = {
	selected: number,
	setSelected: Function
};

type TabProps = {
	id: number,
	selected: number,
	setSelected: Function,
	title: string
};*/

export const SideBar = ({...props}: any) => {
	// const [selected, setSelected] = useState<number>(1);
	// const [infoData, setInfoData] = useState({});

	return (
		<div className={styles.main}>
			<div>
				{/* <Tabs
					selected={selected}
					setSelected={setSelected}
				/> */}
			</div>
			<SideBarSection
				selected={props.selected}
				type={props.type}
			/>
		</div>
	);
};

const SideBarSection = ({...props}: any) => {
	if (props.type === "person" && props.selected.length > 0) {
		return (
			<PersonView
				selected={props.selected[0] || null}
			/>
		);
	} else {
		return (
			<div />
		);
	}
}

/* Tabs */

/*const Tabs = ({ selected, setSelected }: TabsProps) => {
	const tabs = [
		{
			title: "Info"
		},
		{
			title: "Tasks"
		},
		{
			title: "Dealflow"
		}
	];
	return (
		<div className={styles.tabs}>
			{
				tabs.map((tab, index) => <Tab
					id={index}
					selected={selected}
					setSelected={setSelected}
					title={tab.title}
				/>)
			}
		</div>
	);
}

const Tab = ({ id, selected, setSelected, title }: TabProps) => {
	const select = () => {
		setSelected(id);
	}
	return (
		<h1 onClick={select}>{ title }</h1>
	);
}*/

/* Display */

const Section = ({ children }: any) => {
	return (
		<div className={styles.section}>
			{children}
		</div>
	);
}

type SectionHeaderProps = {
	children: ReactNode,
	name: string,
	logo: string
}

const SectionHeader = ({ children, ...props }: SectionHeaderProps) => {
	return (
		<div className={styles.sectionHeader}>
			<h1>{props.name}</h1>
			<div className={styles.sectionHeaderContent}>
				<SectionIcon
					image={props.logo}
					name={props.name}
				/>
				<div className={styles.sectionHeaderDetails}>
					{children}
				</div>
			</div>
		</div>
	);
}

type SectionIconProps = {
	image?: string,
	name: string
};

const SectionIcon = ({ ...props }: SectionIconProps) => {
	return (
		props.image ?
			// <img className={styles.sectionIcon} src={props.image} alt="Logo" />
			<img
				src={"https://pearos-people-photos.s3-us-west-2.amazonaws.com/" + props.image}
				alt=""
				className={styles.sectionIcon}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null;
					// row.row["photo"] = null;
					currentTarget.src= profile;
				}}
			/>
		:
			<div className={styles.sectionIconPlaceholder}>
				<p>{props.name ? props.name.split(" ").map((x: any) => x.toUpperCase()[0]).join("") : ""}</p>
			</div>
	);
}

const Callout = ({children, ...props}: any) => {
	return (
		<div className={styles.callout}>
			<div className={styles.right} />
			{children}
		</div>
	);
}

const CalloutConnections = ({...props}: any) => {
	const [page, setPage] = useState<number>(0);
	const pageLength = 6;
	const totalPages = Math.ceil(props.data.length/pageLength);

	const next = () => {
		if (page < totalPages - 1) {
			setPage(page + 1);
		}
	}

	const previous = () => {
		if (page > 0) {
			setPage(page - 1);
		}
	}

	return (
		<div className={styles.calloutContent}>
			<CalloutPersonPage
				items={props.data.slice(page*pageLength, page*pageLength + pageLength)}
			/>
			<div className={styles.calloutNavigator}>
				<div className={page > 0 ? styles.activeNavigator : styles.inactiveNavigator} onClick={previous}>
					<img className={styles.navigatorArrow} src={leftArrow} alt="previous" />
				</div>
				<div className={page < totalPages - 1 ? styles.activeNavigator : styles.inactiveNavigator} onClick={next}>
					<img className={styles.navigatorArrow} src={rightArrow} alt="next" />
				</div>
			</div>
		</div>
	);
}

const CalloutPersonPage = ({...props}: any) => {
	return (
		<div className={styles.calloutPersonPage}>
			{
				props.items.map((x: any) => 
					<div className={styles.calloutPerson}>
						<h1>{x.first_name + " " + x.last_name}</h1>
						<span>
							{
								x.types.map((y: any) =>
									y === "linkedin" ?
										<a href={x["linkedin"]}><img className={styles.calloutLinkedIn} src={linkedInLogo} alt="LinkedIn" /></a>
									:
										<a href={x["linkedin"]}><img className={styles.calloutLinkedIn} src={meetingLogo} alt="Meeting" /></a>
								)
							}
						</span>
					</div>
				)
			}
		</div>
	);
}

/*const Hoverable = ({children}: any) => {
	const [hovered, setHovered] = useState(false);

	return (
		<div>
			{children}
		</div>
	);
}*/

const Stats = ({...props}: any) => {
	const [showConnections, setShowConnections] = useState(false);
	let connections = [
		...((props.connections || []).map((x: any) => {
			x["type"] = "linkedin";
			return x;
		})),
		...((props.meetingConnections || []).map((x: any) => {
			x["type"] = "meeting";
			return x;
		}))
	];
	connections = Object.values(connections.reduce((prev, curr) => {
		if (curr.id in prev) {
			prev[curr.id]["types"].push(curr.type);
		} else {
			prev[curr.id] = curr;
			prev[curr.id]["types"] = [curr["type"]];
		}
		return prev;
	}, {}));

	return (
		<div className={styles.stats}>
			<div onMouseEnter={() => setShowConnections(true)} onMouseLeave={() => setShowConnections(false)}>
				<Circle
					color="#61C554"
					value={connections ? connections.length : 0}
					round
					size="large"
					total={connections ? connections.length : 0}
					title="Mutual"
				/>
				{ showConnections &&
					<Callout>
						<CalloutConnections
							data={connections}
						/>
					</Callout>
				}
			</div>
			<div>
				<Circle
					value={props.signal*10}
					size="large"
					total={100}
					title="Signal"
					percent
				/>
			</div>
		</div>
	);
}

const Divider = () => {
	return (
		<div className={styles.divider} />
	);
}


const StyledInput = styled(InputBase)(({ theme }) => ({
	padding: 10,
	width: '100%',
	fontFamily: `"Brandon Grotesque", sans-serif`,
	'& input': {
	  borderRadius: 4,
	  backgroundColor: '#fff',
	  padding: 8,
	  transition: theme.transitions.create(['border-color', 'box-shadow']),
	  border: `1px solid #000000`,
	  fontSize: 14,
	  '&:focus': {
		boxShadow: `0px 0px 0px 1px rgb(0,0,0)`,
		borderColor: '#000000',
	  },
	},
}));

type Option = {
	name: String,
	id: Number
}

const SubSection = ({children, editable, ...props}: any) => {	
	const [initialized, setInitialized] = useState<boolean>(false);
	const [editing, setEditing] = useState(false);
	const [adding, setAdding] = useState(false);
	const [displayValues, setDisplayValues] = useState<Option[]>((typeof props.items === 'undefined') ? [] : ((props.items[0].id === null) ? [] : props.items)); 

	const [options, setOptions] = useState<Option[]>([]);

	const [lastDeleted, setLastDeleted] = useState<Number>(-1);
	const [lastAdded, setLastAdded] = useState<Number>(-1);

	const user = window.localStorage.getItem("user");

	const editClick = () => {
		setEditing(true);
	}

	const addClick = () => {
		setAdding(true);
	}

	const cancel = () => {
		setAdding(false);
		setEditing(false);
	}

	const deleteList = (v: any) => {
		var filteredValues = displayValues.filter(e => e.name !== v.name);
		setDisplayValues(filteredValues);
		setEditing(false);
		setLastDeleted(v.id);
		setLastAdded(-1);
	}

	useEffect(() => {
		async function getLists() {
			let response = await authRequest(Endpoints.CRM_LIST, Methods.GET, {});
			setOptions(response.data);
		};
		async function addToList() {
			if (user) {
				let response = await authRequest(Endpoints.CRM_PERSON_LIST, Methods.POST, {
					person: props.id,
					list: lastAdded
				});
				console.log(response);
			}
		};
		async function deleteFromList() {
			if (user) {
				let response = await authRequest(Endpoints.CRM_DELETE_LIST, Methods.POST, {
					person: props.id,
					list: lastDeleted
				});
				console.log(response);
			}
		};
		if (!initialized) {
			getLists();
			setInitialized(true);
		} else if (lastAdded !== -1) {
			addToList();
		} else if (lastDeleted !== -1) {
			deleteFromList();
		}
	}, [lastAdded, lastDeleted, initialized, user, props.id]);

	const inValues = (option: Option) => {
		for (let i = 0; i < displayValues.length; i++){
			if (option.id === displayValues[i].id && option.name === displayValues[i].name) {
				return true;
			}
		}
		return false;
	}

	return (
		<div className={styles.subSection}>
			<div className={styles.editSubSection}>
				<h1>{props.title}</h1> 
				{((editable === "true") && !editing && !adding) && 
					<div>
						{(displayValues.length > 0) && <button onClick={editClick} id={styles.editButton}>
							&#10134;
						</button>}
						<button onClick={addClick} id={styles.editButton}>
							&#x2795;
						</button>
					</div>
				}
			</div>
			{children}
			{(!editing && !adding && editable === "true") && 
				<div className={styles.tileList}>
					{
						displayValues.map((x) => {
							return (<Tile title={x.name} key={x.name} />);
						})
					}
				</div>
			}
			{adding && // Use the "open" tage on Autocomplete instead?
				<div>
					<Autocomplete
						loading = {displayValues.length === 0}
						disableCloseOnSelect	
						getOptionLabel={(option) => (option.name).toString() ?? option}
						disablePortal
						id="combo-box-demo"
						options={options}
						renderOption={(props: object, option: any, {selected}) => (
							<div {...props}>
								<Box
									component={DoneIcon}
									sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
									style={{
									visibility: inValues(option) ? 'visible' : 'hidden',
									}}
								/>
								<Tile title={option.name}/>
							</div>
						)}
						onChange={(event, newValue, reason) => {
							console.log(newValue,displayValues);
							if (newValue !== null) {
								if (inValues(newValue) === false) {
									console.log("Here");
									setDisplayValues(displayValues.concat([newValue]));
									setLastAdded(newValue.id);
									setLastDeleted(-1);
									setAdding(false);
								}
							}
						}}
						sx={{ width: 250 }}
						renderInput={(params) => (
							<StyledInput
								ref={params.InputProps.ref}
								inputProps={params.inputProps}
								autoFocus
								placeholder="Search Lists"
							/>
						)}
					/>
					<button className={styles.cancel} onClick={cancel}>
						Cancel
					</button>
				</div>
			}
			{ editing && 
				<div className={styles.editing}>
					<div className={styles.deleteTileList}>
					{
						displayValues.map((x) => {
							return (<div className={styles.deleteTile}>
										{x.name}
										<button onClick={() => deleteList(x)} className={styles.deleteButton}>
											&#x2715;
										</button>
									</div>);
						})
					}
					</div>
					<button className={styles.cancel} onClick={cancel}>
						Cancel
					</button>
				</div>
			}
		</div>
	);
}

const TileList = ({...props}: any) => {
	return (
		<div className={styles.tileList}>
			{
				props.items.map((x: any) => {
					let title = x.name + " " + x.year;
					return (<Tile title={title} key={title} />);
				})
			}
		</div>
	);
}

const Tile = ({...props}: any) => {
	return (
		<div className={styles.tile}>{props.title}</div>
	);
}

const RolesList = ({...props}: any) => {
	// let currentYear = new Date().getFullYear();
	let items = props.items;
	items = items.filter((a:any) => {
		try {
			a = a.start.split('-');
			return true;
		} catch {
			return false;
		}
	})
	items = items.sort((a: any, b: any) => {
		a = a.start.split('-');
		b = b.start.split('-');
		return b[0] - a[0] || b[1] - a[1] || b[2] - a[2];
	});
	items = items.slice(0,6);
	return (
		<div className={styles.rolesList}>
			{
				items.map((x: any) => <Role data={x} key={x.role + x.org} />)
			}
		</div>
	);
}

const Role = ({...props}: any) => {
	return (
		<span className={styles.role}>{props.data.role} @ <a href={props.data["domain"]}>{props.data.org}</a> {"(" + props.data.start.split('-')[0] + ")"}</span>
	);
}

const SchoolsList = ({...props}: any) => {
	return (
		<div className={styles.rolesList}>
			{
				props.items.sort((a: any, b: any) => a.start - b.start).map((x: any, index: number) =>
					<School data={x} key={index} />
				)
			}
		</div>
	);
}

const School = ({...props}: any) => {
	return (
		<span className={styles.school}><a href={props.data["domain"]}>{props.data.school}</a>{props.data.degree && props.data.degree["field"] ? ", " + props.data.degree["field"] : ""}</span>
	);
}

/* Person */

const PersonView = ({...props}: any) => {
	const [data, setData] = useState();
	const [current, setCurrent] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function loadData() {
			if (props.selected) {
				setLoading(true);
				let person = await personWith(props.selected);
				console.log(person);
				setData(person);
				setLoading(false);
			}
		}

		if (current !== props.selected) {
			setCurrent(props.selected);
			loadData();
		}
	}, [current, props.selected]);

	return (
		<div className={styles.person}>
			{ data && !loading &&
				<div className={styles.personData}>
					<Section>
						<SectionHeader
							logo={data["photo"]}
							name={data["name"]}
						>
							<h2>{data["primary_email"]}</h2>
						</SectionHeader>
						<Stats
							connections={data["connections"]}
							meetingConnections={data["meeting_connections"]}
							signal={data["signal"]}
						/>
					</Section>
					<Divider />
					{ data["programs"] &&
						<SubSection
							title="Programs"
						>
							<TileList items={data["programs"]} />
						</SubSection>
					}
						<SubSection
							title="Lists"
							editable = "true" 
							items={data["lists"]}
							id={data["id"]}
						>
						</SubSection>
					{ data["roles"] &&
						<SubSection
							title="Roles"
						>
							<RolesList items={data["roles"]} />
						</SubSection>
					}
					{ data["schools"] &&
						<SubSection
							title="Education"
						>
							<SchoolsList items={data["schools"]} />
						</SubSection>
					}
				</div>
			}
			{ loading &&
				<div className={styles.loading}>
					<CircularProgress />
				</div>
			}
		</div>
	);
}