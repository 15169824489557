import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
// import { authWith } from '../api/auth';
import styles from './styles/login.module.scss';
import { useAuth } from '../hooks/useAuth';

export function LoginPage() {
	const [searchParams] = useSearchParams();
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const { login } = useAuth();
	
	useEffect(() => {
		let token = searchParams.get("token");
		if (token) {
			let user = {
				id: searchParams.get("id"),
				primary_email: searchParams.get("primary_email"),
				first_name: searchParams.get("first_name"),
				last_name: searchParams.get("last_name"),
				picture: searchParams.get("picture"),
				token: token
			};
			login(user);
		}
	}, [login, searchParams]);
	
	return (
		<div className={styles.main}>
			{ !isLoggingIn &&
				<div className={styles.content}>
					<h1>Welcome to PearOS</h1>
					<LoginButton setLoggingIn={setIsLoggingIn} />
				</div>
			}
			{ isLoggingIn &&
				<div className={styles.content}>
					<CircularProgress />
				</div>
			}
		</div>
	);
};

const LoginButton = ({...props}: any) =>  {
	function startLogin() {
		props.setLoggingIn(true);
		window.location.href = "https://q6kfbwifh3.execute-api.us-west-2.amazonaws.com/v3/auth/oauth2?provider=google&state=" + Math.round(Math.random() * 100000);
	}

	return (
		<button className={styles.button} id="button" onClick={startLogin}>Login</button>
	);
}
