import { Routes, Route } from "react-router-dom";
import './App.css';

import { HomeLayout } from "./components/views/home";
import { ProtectedLayout } from "./components/views/protected";
import { DashboardPage } from "./pages/dashboard";
import { LoginPage } from './pages/login';
import { RankingPage } from "./pages/ranker";
import { SplashPage } from "./pages/splash";

function App() {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<LoginPage />} />
        {/* <Route path="/login" element={<LoginPage />} /> */}
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route path="/welcome" element={<SplashPage />} />
        <Route path="/dashboard" element={<DashboardPage/>}/>
        <Route path="/dashboard/:id" element={<DashboardPage/>}/>
        <Route path="/rank" element={<RankingPage />} />
      </Route>
    </Routes>
  );
}

export default App;
