import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Splash from '../components/views/splash';

export function SplashPage() {
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate("/dashboard");
		}, 4000);
	});

	return (
		<div>
			<Splash />
		</div>
	);
};