import { PieChart } from "react-minimal-pie-chart";
import styles from './styles/indicators.module.scss';

export const Circle = ({...props}: any) => {
	let colors = ["#D9D9D9", "#EC6A5E", "#F5BF4F", "#61C554"];
	let color = colors[0];
	if (props.value === 0) {
		color = colors[0];
	} else if (props.value/props.total > 0.66) {
		color = colors[3];
	} else if (props.value/props.total > 0.33) {
		color = colors[2];
	} else {
		color = colors[1];
	}
	const label:string = props.round ? (props.percent ? Math.round(props.value)+'%' : Math.round(props.value)+'')
	: (props.percent ? props.value.toFixed(0)+'%' : props.value.toFixed(0)+'');
	return (
		<div className={styles.circle}>
			<PieChart
				data={[
					{ title: "1", value: props.value, color: props.color || color }
				]}
				style={{ width: props.size === "large" ? '70px' : '40px' }}
				lineWidth={props.size === "large" ? 25 : 20}
				rounded
				label={() => label}
				labelStyle={(index) => ({
					fill: props.color && props.value > 0 ? props.color : color,
					fontSize: props.size === "large" ? (label.length > 2 ? '25px' : '30px') : '25px',
					fontFamily: 'sans-serif',
					fontWeight: 700
				})}
				background='#D9D9D9'
				labelPosition={0}
				animate
				totalValue={props.total}
				startAngle={270}
			/>
			{ props.title && <h4>{props.title}</h4> }
		</div>
	);
}

export const Signal = ({...props}: any) => {
	let colors = ["#D9D9D9", "#EC6A5E", "#F5BF4F", "#61C554"];
	let color = colors[0];
	if (props.value === 0) {
		color = colors[0];
	} else if (props.value > 6.6) {
		color = colors[3];
	} else if (props.value > 3.3) {
		color = colors[2];
	} else {
		color = colors[1];
	}

	return (
		<div className={styles.signal}>
			<div className={styles.signalColor} style={{backgroundColor: color}} />
			<h1>{Math.round(props.value * 10)}%</h1>
		</div>
	);
}

export const Connections = ({...props}: any) => {

	let slice = Math.floor((props.width-50)/23); // need to figure it out
	let connections = props.connections.slice(0, slice);
	let pear = props.pear.reduce((previous: any, current: any) => {
		previous[current.id] = current.photo;
		return previous;
	}, {});

	return (
		<div className={styles.connections}>
			{
				connections.map((x:any, index:number) =>
					x["id"] in pear ?
						<div
							className={styles.connectionPlaceholder}
							style={{
								backgroundImage: "url(https://pearos-people-photos.s3-us-west-2.amazonaws.com/" + pear[x["id"]] + ")",
								left: index * 23
							}}
							key={ index }
						/>
						:
						<div
							className={styles.connectionPlaceholder}
							style={{
								left: index * 23
							}}
							key={ index }
						>
							{x["i"]}
						</div>
				)
			}
			{
				props.connections.length > slice &&
				<div
					className={styles.connectionAdditional}
					style={{
						left: slice * 23
					}}
					key={ slice+1 }
				>
					+{props.connections.length-slice}
				</div>
			}
		</div>
	);
}