import { createContext, ReactNode, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

interface AuthContextInterface {
	user: any,
	login: Function,
	logout: Function
}

interface Props {
	children?: ReactNode
}

const AuthContext = createContext<AuthContextInterface | any>({});

export const AuthProvider = ({ children, ...props }: Props) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const login = useCallback(async (data: any) => {
    // Validate
    // let user = await authRequest(Endpoints.SELF, Methods.GET, {
		// 	token: data.token
		// });
    if (data) {
      setUser(data);
      navigate("/welcome", { replace: true });
    }
  }, [navigate, setUser]);

  const logout = useCallback(() => {
    setUser(null);
    navigate("/", { replace: true });
  }, [navigate, setUser]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user, login, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};