import { ReactNode } from "react";
import { NavBar } from "../elements/navbar";
import styles from './styles/split.module.scss';

type Props = {
	children?: ReactNode
}

export const SplitLayout = ({ children }: Props) => {
	return (
		<div className={styles.main}>
			<NavBar />
			{ children }
		</div>
	);
};
